import React from "react"
import queryString from "query-string"
import {useStaticQuery, graphql} from "gatsby"

import Card from "../../components/card"
import { FormFieldInput, FormFieldTextArea, FormFieldFile  } from "../../components/form-fields"

import ApplicationFormLogic from "../../components/application-form-logic"
import ApplicationFormContainer from "../../components/application-form-container"

export class CommunityPlanForm extends ApplicationFormLogic {
  render() {
    return (
      <ApplicationFormContainer
        data={this.props.data}
        state={this.state}
        closeModal={this.closeModal}
        handleInputChange={this.handleInputChange}
        handleSubmit={this.handleSubmit}
        handleSave={this.handleSave}
        >
        <Card sectionTitle="Project" sectionSubtitle="">
          <FormFieldInput
            labelText="Project Name"
            name="projectName"
            type="text"
            value={this.state.projectName}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            required="required"
          />

          <FormFieldInput
            labelText="Neighborhood Name"
            name="neighborhoodName"
            type="text"
            value={this.state.neighborhoodName}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="Briefly describe the target community."
            name="targetCommunity"
            type="text"
            value={this.state.targetCommunity}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="Describe the planning process and the community plan
                      or strategy that resulted. Please focus on how the plan
                      engaged community residents, local and citywide
                      institutions and the public and private sectors."
            name="planProcess"
            type="text"
            value={this.state.planProcess}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="If the plan is led by one organization, describe
                      how it has partnered with other community organizations
                      and community members to take ownership of various
                      aspects of the plan. If the plan is led by multiple
                      organizations, describe how the organizations worked
                      as a group and with community members to develop and
                      implement the plan."
            name="partnership"
            type="text"
            value={this.state.partnership}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="Describe the impact of the plan or strategy in the community to date."
            name="impactCommunity"
            type="text"
            value={this.state.impactCommunity}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="Briefly explain the goals, objectives and outcomes of the plan."
            name="planObjectives"
            type="text"
            value={this.state.planObjectives}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="Describe the impact of the plan or strategy on
                      the built environment to date (for example,
                      commercial and industrial properties, housing,
                      open space, community facilities, land use)."
            name="impactEnvironment"
            type="text"
            value={this.state.impactEnvironment}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="Describe the impact of the plan or strategy on
                      addressing quality of life needs or opportunities
                      such as health, social services, safety, education
                      and economic development."
            name="impactHealth"
            type="text"
            value={this.state.impactHealth}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="What have been the major obstacles or challenges
                      to implementation and how have they been overcome?"
            name="projectObstacles"
            type="text"
            value={this.state.projectObstacles}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />
        </Card>

        <Card sectionTitle="Media" sectionSubtitle={this.props.data.mediaSectionSubhead}>
          <FormFieldFile
            labelText="Plan Document"
            allowMultiple={false}
            maxFiles={1}
            acceptedFileTypes={['application/pdf']}
            handleProcessFile={this.handleProcessPlan}
            handleRemoveFile={this.handleRemovePlan}
            files={this.state.plan}
            disabled={this.state.submitted}
            name="plan"
            helpText="Only PDF files are accepted. Files must be a maximum of 5MB in size."
            required="required"
          />

          <FormFieldFile
            labelText="Project Budget"
            allowMultiple={false}
            maxFiles={1}
            acceptedFileTypes={[
              'application/pdf',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ]}
            handleProcessFile={this.handleProcessBudget}
            handleRemoveFile={this.handleRemoveBudget}
            files={this.state.budget}
            disabled={this.state.submitted}
            name="budget"
            helpText="Only PDF, .xlsx, and .docx files are accepted. Files must be a maximum of 5MB in size."
            required="required"
          />

          <FormFieldFile
            labelText="Supporting Materials"
            acceptedFileTypes={['image/*', 'application/pdf']}
            handleProcessFile={this.handleProcessFile}
            handleProcessFiles={this.handleProcessFiles}
            handleRemoveFile={this.handleRemoveFile}
            files={this.state.files}
            disabled={this.state.submitted}
            name="uploadPhotos"
            helpText="Only image files (.png, .jpg, etc.) and PDFs are accepted. Files must be a maximum of 5MB in size."
          />

          <FormFieldInput
            labelText="Video Link"
            name="videoLink"
            type="text"
            value={this.state.videoLink}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            helpText="Link to a video hosted on a video-sharing service like Dropbox or YouTube."
          />
        </Card>

        <Card sectionTitle="Contact" sectionSubtitle="">
          <FormFieldInput
            labelText="Name"
            name="applicantName"
            type="text"
            value={this.state.applicantName}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            required="required"
          />

          <FormFieldInput
            labelText="Organization"
            name="applicantOrganization"
            type="text"
            value={this.state.applicantOrganization}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            required="required"
          />

          <FormFieldInput
            labelText="Title"
            name="applicantTitle"
            type="text"
            value={this.state.applicantTitle}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="Address"
            name="applicantAddress"
            type="text"
            value={this.state.applicantAddress}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldInput
            labelText="Phone Number"
            name="applicantPhone"
            type="text"
            value={this.state.applicantPhone}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            required="required"
          />

          <FormFieldInput
            labelText="Email"
            name="applicantEmail"
            type="text"
            value={this.state.applicantEmail}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            required="required"
          />

          <FormFieldInput
            labelText="How did you learn about the opportunity to apply for this award?"
            name="applicantReferral"
            type="text"
            value={this.state.applicantReferral}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />
        </Card>
      </ApplicationFormContainer>
    );
  }
}

export default props => {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: {eq: "applications/community-plan.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1000, maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  data.applicationType="community-plan"
  data.fields=[
   'projectName', 'neighborhoodName',
   'targetCommunity', 'planProcess', 'partnership', 'impactCommunity',
   'impactEnvironment', 'impactHealth', 'planObjectives', 'projectObstacles',
   'videoLink',
   'applicantName', 'applicantOrganization', 'applicantTitle',
   'applicantAddress', 'applicantPhone', 'applicantEmail', 
   'applicantReferral'
  ]

  data.title="Community Plan Award"
  data.titleText="The Chicago Community Trust Outstanding Community Plan Award"
  data.awardDescription="The Chicago Community Trust Outstanding Community Plan Award recognizes the development and successful implementation of a community plan or strategy within Cook County that improves quality-of-life or addresses a specific need, opportunity or issue through a comprehensive planning and implementation model."
  data.awardLink="https://lisc-cnda.org/awards/community-plan"
  data.headerImagePath="applications/community-plan.jpg"
  data.headerImageLink="https://map.lisc-cnda.org/2019/cnda/northwest-side-housing-center-for-the-belmont-cragin-quality-of-life-plan"
  data.headerImageProject="Northwest Side Housing Center for the Belmont-Cragin Quality-of-Life Plan"
  data.headerImageDescription=", a 2019 Community Plan Award winner"
  data.mediaSectionSubhead="A project budget and plan document are required. Up to 10 supporting materials or a video link can also be included but are optional."
  data.optionalMedia=true

  return (
    <CommunityPlanForm
      data={data}
      uuid={queryString.parse(props.location.search).uuid}
  />
  )
}
